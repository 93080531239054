<template>
  <div>
    <ViewHeader :serial="$route.params.serial" @picker="pickerEventHandler" range="today"/>
    <div v-if="loading" class="row">
      <div class="col-12 align-self-center text-center">
        <LoadingAnimation/>
      </div>
    </div>
    <div v-else>
      <div v-if="noData" class="row">
        <div class="col-12 align-self-center text-center no-data">
          <h1>No data ¯\_(ツ)_/¯ </h1>
          <h3 class="text-secondary"> {{ this.serial }}</h3>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-12">
          <h3>Voltages</h3>
          <LineChart :chart-data="values" chart-title="voltages (V)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventbus.js'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import LineChart from '@/components/LineChart.vue'
import ViewHeader from '@/components/ViewHeader.vue'
import axios from '@/axios-auth.js'

export default {
  data () {
    return {
      loading: true,
      voltages: {},
      values: [],
      serial: '',
      noData: false,
      from: null,
      to: null
    }
  },
  methods: {
    loadVoltages () {
      this.loading = true
      axios.get('/device/' + this.serial + '/voltages', {
        params: {
          vtype: ['v_in', 'v_internal'],
          from: this.from,
          to: this.to,
          max: 100
        }
      })
        .then((response) => {
          this.voltages = response.data
          this.values = []
          this.organizeChartData('v_in')
          this.organizeChartData('v_internal')
          // this.organizeChartData('v_external')
          this.noData = false
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else if (e.response.status === 404) {
            this.noData = true
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading the gps stats. Please try again later.')
            this.$router.push({ name: 'deviceSelection' })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getMetricColor (metric) {
      switch (metric) {
        case 'v_in':
          return 'blue'
        case 'v_internal':
          return 'red'
        case 'v_external':
          return 'green'
        default:
          return 'black'
      }
    },
    organizeChartData (metric) {
      const value = {
        label: metric,
        borderColor: this.getMetricColor(metric),
        fill: false,
        lineTension: 0,
        data: []
      }
      const l = this.voltages[metric].length
      for (let i = 0; i < l; i++) {
        // reverse order while organizing
        value.data[l - 1 - i] = {
          x: new Date(this.voltages[metric][i].date),
          y: this.voltages[metric][i].value
        }
      }
      this.values.push(value)
    },
    pickerEventHandler (from, to) {
      this.from = from
      this.to = to
      this.loadVoltages()
    }
  },
  mounted () {
    this.serial = this.$route.params.serial
    // emit the serial in case it was set by the user in the url
    EventBus.$emit('serial', this.serial)
  },
  components: {
    LoadingAnimation,
    LineChart,
    ViewHeader
  }
}
</script>

<style scoped>
.no-data {
  margin-top: 20vh;
}
</style>
