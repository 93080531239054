import { render, staticRenderFns } from "./Voltages.vue?vue&type=template&id=5098eb62&scoped=true"
import script from "./Voltages.vue?vue&type=script&lang=js"
export * from "./Voltages.vue?vue&type=script&lang=js"
import style0 from "./Voltages.vue?vue&type=style&index=0&id=5098eb62&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5098eb62",
  null
  
)

export default component.exports