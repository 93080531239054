import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
  paramsSerializer: params => {
    return Object.entries(params).map(([key, value]) => {
      if (Array.isArray(value)) {
        // Handle array parameters
        return value.map(element => `${encodeURIComponent(key)}=${encodeURIComponent(element)}`).join('&')
      }
      // Handle date objects
      if (value instanceof Date) {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value.toISOString())}`
      }
      // Handle other types of parameters
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    }).join('&')
  }
})
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (!token) {
      throw new Error('no token')
    }
    config.headers.Authorization = 'Bearer ' + token
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default instance
