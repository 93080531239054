<template>
  <div>
    <div class="row">
      <div class="col-12">
        <form class="mb-2">
          <div class="input-group">
            <input v-if="!projectsModal" type="text" placeholder="search for a device" class="form-control" v-model="deviceSearchQuery">
            <input v-else type="text" placeholder="Filter projects" class="form-control" v-model="projectsFilter">
            <div class="input-group-append">
              <button class="btn btn-primary" title="Search everywhere" @click.prevent="searchForDevice()">🔍</button>
            </div>
          </div>
          <div class="input-group my-2">
            <button v-show="!projectsModal" class="btn btn-primary mr-1" title="Limit search to matching device names" @click.prevent="searchForDevice('name')">Name</button>
            <button v-show="!projectsModal" class="btn btn-primary mx-1" title="Limit search to matching device serials" @click.prevent="searchForDevice('serial')">Serial</button>
            <button v-show="!projectsModal" class="btn btn-primary mx-1" title="Limit search to matching device references" @click.prevent="searchForDevice('ref')">ref</button>
            <button v-show="!projectsModal" class="btn btn-primary mx-1" title="Limit search to matching device id's" @click.prevent="searchForDevice('id')">ID</button>
            <button :class="[projectsSearched.length > 0 ? 'btn-warning' : 'btn-success', 'btn ml-auto']" title="Limit search to the selected projects" @click.prevent="toggleProjectModal()">Projects</button>
          </div>
        </form>
      </div>
    </div>
    <div class="row mb-2">
      <div v-if="loading" class="col-12 align-self-center text-center">
        <LoadingAnimation/>
      </div>
      <div v-else-if="projectsModal" class="col-12">
        <h2 v-if="projects.length == 0" class="text-center">Loading projects ...</h2>
        <button v-for="project in filteredProjects" :key="project.pro_id" :class="[project.selected ? 'btn-dark' : 'btn-light', 'btn mr-2 my-1']" @click="toggleProject(project)">
          {{ project.name }} ({{ project.pro_id }})
        </button>
      </div>
      <div v-else-if="noSearch" class="col-12 justify-content-center text-center">
        <div class="resultDialog">
          <img src="@/assets/search.svg">
          <p>Start searching to list your devices</p>
        </div>
      </div>
      <div v-else-if="noResult" class="col-12 justify-content-center text-center">
        <div class="resultDialog">
          <p>No devices found</p>
        </div>
      </div>
      <div v-else v-for="device in devices" :key="device.id" class="col-sm-6 col-md-4 col-xl-2 my-1" @click="selectDevice(device)">
        <div class="card h-100">
          <h6 class="card-header text-center"><b>{{ device.name }}</b></h6>
          <div class="card-text pl-2 mt-2">
            <p v-if="device.user_ref != ''"><b>ref</b> : {{ device.user_ref }}</p>
            <p><b>serial</b> : {{ device.serial }}</p>
            <p><b>project</b> : {{ device.project_name }}</p>
          </div>
        </div>
      </div>
      <div v-if="devices.length == 100" class="col-12">
        <h4 class="text-center text-secondary my-2"><i>Only the first 100 results are displayed. Try refining your search if your device isn't listed.</i></h4>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventbus.js'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import axios from '@/axios-auth.js'

export default {
  name: 'deviceSelection',
  data () {
    return {
      projectsModal: false,
      noSearch: true,
      noResult: false,
      loading: false,
      deviceSearchQuery: '',
      projectsFilter: '',
      projectSelectionChanged: false,
      projectsSearched: [],
      devices: [],
      projects: []
    }
  },
  methods: {
    searchForDevice (filter) {
      this.deviceSearchQuery = this.deviceSearchQuery.trim()
      // need at least one char before searching
      if (this.deviceSearchQuery === '') {
        return
      }

      this.projectsModal = false
      this.noSearch = false
      this.loading = true

      const params = {
        search: this.deviceSearchQuery,
        project: this.projectsSearched, // projectsSearched will be omitted if null
        max: 100,
        ...(filter != null && { filter }) // conditionally add filter if not null or undefined
      }

      axios.get('/devices', { params })
        .then((response) => {
          this.noResult = false
          this.devices = response.data
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 400) {
            EventBus.$emit('alert', 'error', 'Invalid search term.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else if (e.response.status === 404) {
            this.noResult = true
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while searching for devices. Please try again later.')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectDevice (device) {
      // Go to selected's device details page
      this.$router.push({ name: 'deviceDetails', params: { serial: device.serial } })
    },
    toggleProject (project) {
      project.selected = !project.selected
      this.projectSelectionChanged = true // need to update project list in query
    },
    toggleProjectModal () {
      this.projectsModal = !this.projectsModal
      if (this.projectSelectionChanged) {
        // filter selected project, then only keep pro_id's
        this.projectsSearched = this.projects.filter(p => p.selected).map(p => p.pro_id)
        this.projectSelectionChanged = false
      }
    },
    loadProjects () {
      axios.get('/projects')
        .then((response) => {
          this.projects = response.data.map(p => ({ ...p, selected: false }))
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading the projects.')
          }
        })
    }
  },
  computed: {
    filteredProjects () {
      if (this.projectsFilter.trim === '') {
        return this.projects
      }
      return this.projects.filter(p => {
        // filter on case insensitive project name or project id
        return p.name.toUpperCase().includes(this.projectsFilter.toUpperCase()) || p.pro_id.toString().includes(this.projectsFilter)
      })
    }
  },
  mounted () {
    this.loadProjects()
  },
  components: {
    LoadingAnimation
  }
}
</script>

<style scoped>
  .resultDialog {
    margin-top: 20vh;
    font-size: 24px;
  }
  .card {
    cursor: pointer;
  }
</style>
